/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "@fontsource/kumbh-sans/300.css"
import "@fontsource/kumbh-sans/400.css"
import "@fontsource/kumbh-sans/700.css"

import "@fontsource/lora/400.css"
import "@fontsource/lora/500.css"
import "@fontsource/lora/600.css"

import "./src/styles/reset.css"
import "./src/styles/fonts.css"
import "./src/styles/base.css"
